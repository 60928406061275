import React from 'react'

import BivControlBattery from "components/BivControlBattery/BivControlBattery.jsx";
import BivControlComun from "components/BivControlComun/BivControlComun.jsx";
import BivControlProcess from "components/BivControlProcess/BivControlProcess.jsx";
import BivControlStorage from "components/BivControlStorage/BivControlStorage.jsx";



const languages = require('../../../assets/translate/translate_proctoringControl.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

class TabAmbiente extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      controlExpanded: "",
      processListFilterText: "",
      processNewItemDialog: false,
    }
  }

  handleExpandClick(x) {
    if (this.state.controlExpanded === x) {
      this.setState({ controlExpanded: "" })
    } else {
      this.setState({ controlExpanded: x })
    }
  }

  render() {

    return (
      <div style={{ display: 'flex', flexDirection: "column", overflow: 'hidden' }}>

        <span>

          <BivControlBattery
            values={this.props.values.bl}

            switchText={languages[lang].proctoringC_bateryLM}
            switchOnChange={() => {
              if (!global.readOnlyUser) {
                this.setState({}, () => {
                  this.props.values.bl.a = !this.props.values.bl.a
                  if (this.props.values.bl.a) {
                    //this.state.controlExpanded = "blA"
                    this.setState({ controlExpanded: "blA" }, this.props.checkChanges())
                  } else {
                    this.setState({ controlExpanded: "" }, this.props.checkChanges())
                  }
                })
              }
            }}
            expandValue={(this.state.controlExpanded === "blA") ? true : false}
            expandId="blA"
            expandOnChange={this.handleExpandClick.bind(this, "blA")}

            initialPriorityOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.bl.ip = number
                this.props.checkChanges()
              })
            }}
            batteryLevelOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.bl.bl = number
                this.props.checkChanges()
              })
            }}

            changePriorityFromOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.bl.t.v = number
                this.props.checkChanges()
              })
            }}
            changePriorityToOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.bl.t.p = number
                this.props.checkChanges()
              })
            }}
          />

          <BivControlStorage
            values={this.props.values.cs}

            switchText={languages[lang].proctoringC_controlOFASSpace}
            switchOnChange={() => {
              if (!global.readOnlyUser) {
                this.setState({}, () => {
                  this.props.values.cs.a = !this.props.values.cs.a
                  if (this.props.values.cs.a) {
                    //this.state.controlExpanded = "csA"
                    this.setState({ controlExpanded: "csA" }, this.props.checkChanges())
                  } else {
                    this.setState({ controlExpanded: "" }, this.props.checkChanges())
                  }
                  this.props.checkChanges()
                })
              }
            }}
            expandValue={(this.state.controlExpanded === "csA") ? true : false}
            expandId="csA"
            expandOnChange={this.handleExpandClick.bind(this, "csA")}

            initialPriorityOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.cs.ip = number
                this.props.checkChanges()
              })
            }}
            storageRequiredOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.cs.mb = number
                this.props.checkChanges()
              })
            }}

            changePriorityFromOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.cs.t.v = number
                this.props.checkChanges()
              })
            }}
            changePriorityToOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.cs.t.p = number
                this.props.checkChanges()
              })
            }}
          />

          <BivControlComun
            values={this.props.values.mc}

            switchText={languages[lang].proctoringC_monitorControl}
            switchOnChange={() => {
              if (!global.readOnlyUser) {
                this.setState({}, () => {
                  this.props.values.mc.a = !this.props.values.mc.a
                  if (this.props.values.mc.a) {
                    //this.state.controlExpanded = "mcA"
                    this.setState({ controlExpanded: "mcA" }, this.props.checkChanges())
                  } else {
                    this.setState({ controlExpanded: "" }, this.props.checkChanges())
                  }
                  this.props.checkChanges()
                })
              }
            }}
            expandValue={(this.state.controlExpanded === "mcA") ? true : false}
            expandId="mcA"
            expandOnChange={this.handleExpandClick.bind(this, "mcA")}

            initialPriorityOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.mc.ip = number
                this.props.checkChanges()
              })
            }}

            changePriorityFromOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.mc.t.v = number
                this.props.checkChanges()
              })
            }}
            changePriorityToOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.mc.t.p = number
                this.props.checkChanges()
              })
            }}
          />
{!JSON.parse(localStorage.siglocanvas)?
          <BivControlComun
            values={this.props.values.vm}

            switchText={languages[lang].proctoringC_virtualMControl}
            switchOnChange={() => {
              if (!global.readOnlyUser) {
                this.setState({}, () => {
                  this.props.values.vm.a = !this.props.values.vm.a
                  if (this.props.values.vm.a) {
                    //this.state.controlExpanded = "vmA"
                    this.setState({ controlExpanded: "vmA" }, this.props.checkChanges())
                  } else {
                    this.setState({ controlExpanded: "" }, this.props.checkChanges())
                  }
                  this.props.checkChanges()
                })
              }
            }}

            expandValue={(this.state.controlExpanded === "vmA") ? true : false}
            expandId="vmA"
            expandOnChange={this.handleExpandClick.bind(this, "vmA")}

            initialPriorityOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.vm.ip = number
                this.props.checkChanges()
              })
            }}

            changePriorityFromOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.vm.t.v = number
                this.props.checkChanges()
              })
            }}
            changePriorityToOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.vm.t.p = number
                this.props.checkChanges()
              })
            }}
          />
          :null}
          <BivControlProcess
            values={this.props.values.pc}

            switchText={languages[lang].proctoringC_processControl}
            switchOnChange={() => {
              if (!global.readOnlyUser) {
                this.setState({}, () => {
                  this.props.values.pc.a = !this.props.values.pc.a
                  if (this.props.values.pc.a) {
                    //this.state.controlExpanded = "pcA"
                    this.setState({ controlExpanded: "pcA" }, this.props.checkChanges())
                  } else {
                    this.setState({ controlExpanded: "" }, this.props.checkChanges())
                  }
                  this.props.checkChanges()
                })
              }
            }}

            expandValue={(this.state.controlExpanded === "pcA") ? true : false}
            expandId="pcA"
            expandOnChange={this.handleExpandClick.bind(this, "pcA")}

            initialPriorityOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.pc.ip = number
                this.props.checkChanges()
              })
            }}

            changePriorityFromOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.pc.t.v = number
                this.props.checkChanges()
              })
            }}

            changePriorityToOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.pc.t.p = number
                this.props.checkChanges()
              })
            }}

            processFilterText={this.state.processListFilterText}

            processOnFilter={(text) => {
              this.setState({ processListFilterText: text }, () => {
                this.props.checkChanges()
              })
            }}

            processOnDelete={(item) => {
              this.setState({}, () => {
                this.props.values.pc.p.splice(item, 1)
                this.props.checkChanges()
              })
            }}

            processOnChange={(item) => {
              this.setState({}, () => {
                this.props.values.pc.p[item.row.key] = { 'pname': item.row.processname }
                this.props.checkChanges()
              })
            }}

            processOnAdd={(value) => {
              this.setState({}, () => {
                let newItem = { 'pname': value }
                this.props.values.pc.p.push(newItem)
                this.props.checkChanges()
              })
            }}

            newItemDialog={this.state.processNewItemDialog}

            togleNewItemDialog={() => {
              this.setState({ processNewItemDialog: !this.state.processNewItemDialog })
            }}

          />

          <BivControlComun
            values={this.props.values.lf}

            switchText={languages[lang].proctoringC_otherToolsADITForeground}
            switchOnChange={() => {
              if (!global.readOnlyUser) {
                this.setState({}, () => {
                  this.props.values.lf.a = !this.props.values.lf.a
                  if (this.props.values.lf.a) {
                    //this.state.controlExpanded = "vmA"
                    this.setState({ controlExpanded: "lf" }, this.props.checkChanges())
                  } else {
                    this.setState({ controlExpanded: "" }, this.props.checkChanges())
                  }
                  this.props.checkChanges()
                })
              }
            }}

            expandValue={(this.state.controlExpanded === "lf") ? true : false}
            expandId="lf"
            expandOnChange={this.handleExpandClick.bind(this, "lf")}

            initialPriorityOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.lf.ip = number
                this.props.checkChanges()
              })
            }}

            changePriorityFromOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.lf.t.v = number
                this.props.checkChanges()
              })
            }}
            changePriorityToOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.lf.t.p = number
                this.props.checkChanges()
              })
            }}
          />
          <BivControlComun
            values={this.props.values.uk}

            switchText={languages[lang].proctoringC_suspiciousKUDetected}
            switchOnChange={() => {
              if (!global.readOnlyUser) {
                this.setState({}, () => {
                  this.props.values.uk.a = !this.props.values.uk.a
                  if (this.props.values.uk.a) {
                    //this.state.controlExpanded = "vmA"
                    this.setState({ controlExpanded: "uk" }, this.props.checkChanges())
                  } else {
                    this.setState({ controlExpanded: "" }, this.props.checkChanges())
                  }
                  this.props.checkChanges()
                })
              }
            }}

            expandValue={(this.state.controlExpanded === "uk") ? true : false}
            expandId="uk"
            expandOnChange={this.handleExpandClick.bind(this, "uk")}

            initialPriorityOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.uk.ip = number
                this.props.checkChanges()
              })
            }}

            changePriorityFromOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.uk.t.v = number
                this.props.checkChanges()
              })
            }}
            changePriorityToOnChange={(number) => {
              this.setState({}, () => {
                this.props.values.uk.t.p = number
                this.props.checkChanges()
              })
            }}
          />

        </span>

      </div>

    )
  }
}

export default TabAmbiente

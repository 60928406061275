/**
 * File to serve all object with columns format
 *
 * Import:
 * import db from 'utilities/columnTable.jsx'
 *
 */

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import utilTable from 'utilities/utilTable.jsx'

import moment from "moment"
moment.locale("es")




const languages = require('../assets/translate/translate_columnTable_all.json')
let lang = localStorage.lang ? localStorage.lang : 'es'




function getTableDashboardExamns(sortFunction) {

  return [
    {
      dataField: "key",
      editable: false,
      hidden: true,
    },
    {
      dataField: "userName",
      text: languages[lang].allCT_nameLN,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '165px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("userName", order) },
      sortFunc: (a, b, order) => { },
    },
    {
      dataField: "file",
      text: languages[lang].allCT_file,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '92px' },
      style: { fontSize: '12px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("file", order) },
      sortFunc: (a, b, order) => { },
    },
    {
      dataField: "examType",
      text: languages[lang].allCT_exam,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '100px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("examType", order) },
      sortFunc: (a, b, order) => { },
    },
    {
      dataField: "subjectName",
      style: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: "132px"},
      formatter: utilTable.tooltip,
      text: languages[lang].allCT_subject,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '98px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("subjectName", order) },
      sortFunc: (a, b, order) => { },
    },
    {
      dataField: "createdAt",
      text: languages[lang].allCT_date,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '88px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("createdAt", order) },
      sortFunc: (a, b, order) => { },
    },
    {
      dataField: "createdAtTime",
      text: languages[lang].allCT_start,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '84px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("createdAt", order) },
      sortFunc: (a, b, order) => { },
    },
    {
      dataField: "finalizatedAt",
      text: languages[lang].allCT_end,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '75px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("finalizatedAt", order) },
      sortFunc: (a, b, order) => { },
    },
    {
      dataField: "qualification",
      text: languages[lang].allCT_mark,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '90px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("qualification", order) },
      sortFunc: (a, b, order) => { },
    },
    {
      dataField: "validation",
      text: languages[lang].allCT_validation,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '117px' },
      sortCaret: utilTable.sortIcon,
      style: utilTable.formatStyleValidation,
      formatter: utilTable.formatIconValidation,
      onSort: (field, order) => { sortFunction("ve", order) },
      sortFunc: (a, b, order) => { },
    },
    {
      dataField: "finalResultAuditory",
      text: languages[lang].allCT_actions,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '110px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("finalResultAuditory", order) },
      sortFunc: (a, b, order) => { },
    },
  ]

}

function getTableInitialExman(sortFunction, validationButtonFunction) {

  return [
    {
      dataField: 'key',
      text: 'key',
      editable: false,
      hidden: true,
    }, {
      dataField: 'userName',
      text: languages[lang].allCT_nameLN,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '165px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("userName", order) },
    }, {
      dataField: 'dni',
      text: global.dni_name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '74px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("dni", order) },
    }, {
      dataField: 'createdAtTime',
      text: languages[lang].allCT_start,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '84px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("createdAt", order) },
    }, {
      dataField: 'finalizatedAt',
      text: languages[lang].allCT_end,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '75px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("finalizatedAt", order) },
    }, {
      dataField: 'createdAt',
      text: languages[lang].allCT_date,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '88px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("createdAt", order) },
    }, {
      dataField: 'validation',
      text: languages[lang].allCT_validation,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '117px' },
      style: utilTable.formatStyleValidation,
      formatter: utilTable.formatIconValidation,
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("ve", order) },
    }, {
      dataField: 'countExam',
      text: languages[lang].allCT_attempts,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '150px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("countExam", order) },
    }, {
      dataField: 'desktopVideo',
      text: languages[lang].allCT_video1,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '100px' },
      style: utilTable.formatStyleIcon,
      formatter: utilTable.formatIconDesktopVideo,
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("dv", order) },
    }, {
      dataField: 'faceVideo',
      text: languages[lang].allCT_video2,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '100px' },
      style: utilTable.formatStyleIcon,
      formatter: utilTable.formatIconFaceVideo,
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("fv", order) },
    }, {
      dataField: 'infoButton',
      text: languages[lang].allCT_actions,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '110px' },
      formatter: validationButtonFunction,
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("infoButton", order) },
    },

  ]

}

function getTableExman(sortFunction, validationButtonFunction) {
  let arr = [{
    dataField: 'key',
    text: 'key',
    editable: false,
    hidden: true,
  }, {
    dataField: 'userName',
    text: languages[lang].allCT_nameLN,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '165px' },
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("userName", order) },
  }, {
    dataField: 'dni',
    text: global.dni_name,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '84px' },
    sortCaret: this.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("dni", order) },
  },

  {
    dataField: 'createdAtTime',
    text: languages[lang].allCT_start,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '84px' },
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("createdAt", order) },
  }, {
    dataField: 'finalizatedAt',
    text: languages[lang].allCT_end,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '75px' },
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("finalizatedAt", order) },
  }, {
    dataField: 'createdAt',
    text: languages[lang].allCT_date,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '88px' },
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("createdAt", order) },
  }, {
    dataField: 'file',
    text: languages[lang].allCT_file,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '92px' },
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("file", order) },
  }]

  if (global._uni_ == '1') {
    arr.push({
      dataField: 'qualification',
      text: languages[lang].allCT_provisoryMark,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '150px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("qualification", order) },
    },
      {
        dataField: 'grade',
        text: languages[lang].allCT_finalMark,
        editable: false,
        sort: true,
        headerStyle: { minWidth: '150px' },
        sortCaret: utilTable.sortIcon,
        sortFunc: (a, b, order) => { },
        onSort: (field, order) => { sortFunction("grade", order) },
      })

  }
  else {
    arr.push({
      dataField: 'qualification',
      text: languages[lang].allCT_qualification,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '150px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("qualification", order) },
    })
  }
  arr.push({
    dataField: 'subjectName',
    text: languages[lang].allCT_subject,
    style: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden",maxWidth: "132px"},
    formatter: utilTable.tooltip,
    editable: false,
    sort: true,
    //Orig min headerStyle: { minWidth: '98px' },
    headerStyle: { minWidth: '150px' },
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("subjectName", order) },
  }, {
    dataField: 'examType',
    text: languages[lang].allCT_examType,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '150px' },
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("examType", order) },
  }, {
    dataField: 'countExam',
    text: languages[lang].allCT_attempts,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '150px', fontSize: 13 },
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("countExam", order) },
  }, {
    dataField: 'validation',
    text: languages[lang].allCT_validation,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '117px' },
    style: utilTable.formatStyleValidation,
    formatter: utilTable.formatIconValidation,
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("ve", order) },
  }, {
    dataField: 'desktopVideo',
    text: languages[lang].allCT_video1,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '100px' },
    style: utilTable.formatStyleIcon,
    formatter: utilTable.formatIconDesktopVideo,
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("dv", order) },
  }, {
    dataField: 'faceVideo',
    text: languages[lang].allCT_video2,
    editable: false,
    sort: true,
    headerStyle: { minWidth: '100px' },
    style: utilTable.formatStyleIcon,
    formatter: utilTable.formatIconFaceVideo,
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("fv", order) },
  }, {
    dataField: 'infoButton',
    text: languages[lang].allCT_actions,
    editable: false,
    sort: true,
    headerStyle: { width: '110px' },
    formatter: validationButtonFunction,
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("infoButton", order) },
  })

  return arr

}

function getTableExmanF13(sortFunction, validationButtonFunction) {
  const fields = global.exam_table_fields
  let arr = []
  arr.push({
    dataField: 'key',
    text: 'key',
    editable: false,
    hidden: true
  });
  if (fields.createdAt.enable) {
    arr.push({
      dataField: 'createdAt',
      text: fields.createdAt.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '85px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("createdAt", order) }
    });
  }

  if (fields.createdAtTime.enable) {
    arr.push({
      dataField: 'createdAtTime',
      text: fields.createdAtTime.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '82px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("createdAt", order) }
    });
  }
  if (fields.finalizatedAt.enable) {
    arr.push({
      dataField: 'finalizatedAt',
      text: fields.finalizatedAt.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '75px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("finalizatedAt", order) }
    });

  }
  if (fields.userName.enable) {
    arr.push({
      dataField: 'userName',
      text: fields.userName.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '158px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("userName", order) }
    });

  }
  if (fields.dni.enable) {
    arr.push(
      {
        style: { maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
        dataField: 'dni',
        text: fields.dni.name,
        editable: false,
        sort: true,
        headerStyle: { minWidth: '100px', fontSize: 13 },
        sortCaret: utilTable.sortIcon,
        sortFunc: (a, b, order) => { },
        onSort: (field, order) => { sortFunction("dni", order) }
      });

  }
  if (fields.user.enable) {
    arr.push(
      {
        dataField: 'user',
        text: fields.user.name,
        editable: false,
        sort: true,
        headerStyle: { minWidth: '100px', fontSize: 13 },
        sortCaret: utilTable.sortIcon,
        sortFunc: (a, b, order) => { },
        onSort: (field, order) => { sortFunction("user", order) }
      })

  }

  if (fields.ip.enable) {
    arr.push({
      dataField: 'ip',
      text: fields.ip.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '120px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("ip", order) }
    })
  }

  if (fields.file.enable) {
    arr.push({
      dataField: 'file',
      text: fields.file.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '100px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("file", order) }
    })

  }
  if (fields.qualification.enable) {
    if (global._uni_ === '1') {
      arr.push({
        dataField: 'qualification',
        text: fields.qualification.name,
        editable: false,
        sort: true,
        headerStyle: { minWidth: '150px', fontSize: 13 },
        sortCaret: utilTable.sortIcon,
        sortFunc: (a, b, order) => { },
        onSort: (field, order) => { sortFunction("qualification", order) }
      });
    }
    else {
      arr.push({
        dataField: 'qualification',
        text: fields.qualification.name,
        editable: false,
        sort: true,
        headerStyle: { minWidth: '120px', fontSize: 13 },
        sortCaret: utilTable.sortIcon,
        sortFunc: (a, b, order) => { },
        onSort: (field, order) => { sortFunction("qualification", order) }
      })
    }
  }
  if (fields.grade.enable) {
    arr.push({
      dataField: 'grade',
      text: fields.grade.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '110px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("grade", order) }
    })
  }
  if (fields.subjectName.enable) {
    arr.push({
      dataField: 'subjectName',
      text: global._lms_ === '4' ? fields.subjectName.name : fields.subjectName.name,
      editable: false,
      sort: true,
      style: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: "132px"},
      formatter: utilTable.tooltip,
      //Orig min headerStyle: { minWidth: '98px' },
      headerStyle: { minWidth: '100px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("subjectName", order) }
    });

  }
  if (fields.examType.enable) {
    arr.push({
      dataField: 'examType',
      text: global._lms_ === '4' ? fields.examType.name : fields.examType.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '144px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("examType", order) }
    })

  }

  if (fields.countExam.enable) {
    arr.push({
      dataField: 'countExam',
      text: fields.countExam.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '110px', fontSize: 13 },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("countExam", order) }
    })
  }
  if (fields.validation.enable) {
    arr.push({
      dataField: 'validation',
      text: fields.validation.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '120px', fontSize: 13 },
      style: utilTable.formatStyleValidation,
      formatter: utilTable.formatIconValidation,
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("ve", order) }
    });

  }
  if (fields.desktopVideo.enable) {
    arr.push({
      dataField: 'desktopVideo',
      text: fields.desktopVideo.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '95px', fontSize: 13 },
      style: utilTable.formatStyleIcon,
      formatter: utilTable.formatIconDesktopVideo,
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("dv", order) }
    });

  }
  if (fields.faceVideo.enable) {
    arr.push({
      dataField: 'faceVideo',
      text: fields.faceVideo.name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '95px', fontSize: 13 },
      style: utilTable.formatStyleIcon,
      formatter: utilTable.formatIconFaceVideo,
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("fv", order) }
    });
  }


  arr.push({
    style: {verticalAlign: 'middle', textAlign: 'center'},
    dataField: 'infoButton',
    text: languages[lang].allCT_actions,
    editable: false,
    sort: true,
    headerStyle: { width: '110px', fontSize: 13 },
    formatter: validationButtonFunction,
    sortCaret: utilTable.sortIcon,
    sortFunc: (a, b, order) => { },
    onSort: (field, order) => { sortFunction("infoButton", order) }
  })
  return arr


}

function getTableRegistry(sortFunction, validationButtonFunction) {

  //sort: true,
  //sortCaret: utilTable.sortIcon,

  return [

    {
      dataField: 'createdAt',
      text: 'Fecha',
      editable: false,
      sort: true,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("createdAt", order) },
      headerStyle: { minWidth: '75px' },
      sortCaret: utilTable.sortIcon,
    },

    {
      dataField: 'start_at',
      text: languages[lang].allCT_start,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '75px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("createdAt", order) },
    },

    {
      dataField: 'finalized_at',
      text: languages[lang].allCT_end,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '75px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("finalized_at", order) },
    },

    {
      dataField: 'user',
      text: languages[lang].allCT_nameLN,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '125px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("Nickname", order) },
    },

    {
      dataField: 'nickname',
      text: languages[lang].allCT_user,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '98px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("Nickname", order) },
    },

    {
      dataField: 'dni',
      text: global.dni_name,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '84px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("dni", order) },
    },
    {
      dataField: "file",
      text: languages[lang].allCT_file,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '92px' },
      style: { fontSize: '12px' },
      sortCaret: utilTable.sortIcon,
      onSort: (field, order) => { sortFunction("file", order) },
      sortFunc: (a, b, order) => { },
    },

    /*{
      dataField: 'detail',
      text: 'Detalle',
      editable: false,
      sort: true,
      headerStyle: { minWidth: '69px' },
      sortCaret: this.sortIcon,
      sortFunc: (a,b,order) => { },
      onSort: (field, order) => { sortFunction("datail", order) },
    }, 
    
    {
      dataField: 'user',
      text: 'Usuario',
      editable: false,
      sort: true,
      headerStyle: { minWidth: '98px' },
      sortCaret: this.sortIcon,
      sortFunc: (a,b,order) => { },
      onSort: (field, order) => { sortFunction("user", order) },
    }, 
    
   /* {
      dataField: 'detalle',
      text: 'detalle',
      editable: false,
      sort: true,
      headerStyle: { minWidth: '137px' },
      sortCaret: this.sortIcon,
      sortFunc: (a,b,order) => { },
      onSort: (field, order) => { sortFunction("detalle", order) },
    },*/
    {
      dataField: 'app_id',
      text: languages[lang].allCT_source,
      editable: false,
      sort: true,
      headerStyle: { minWidth: '137px' },
      sortCaret: utilTable.sortIcon,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("app_id", order) },
    },

    {
      dataField: 'id',
      text: languages[lang].allCT_registryNumber,
      editable: false,
      sort: true,
      sortFunc: (a, b, order) => { },
      onSort: (field, order) => { sortFunction("id", order) },
      headerStyle: { minWidth: '88px' },
      sortCaret: utilTable.sortIcon,
    },
    /*{
      dataField: 'origin',
      text: 'Origen',
      editable: false,
      sort: true,
      headerStyle: { minWidth: '92px' },
      sortCaret: this.sortIcon,
      sortFunc: (a,b,order) => { },
      onSort: (field, order) => { sortFunction("origin", order) },
    }*/ {
      dataField: 'button',
      text: languages[lang].allCT_actions,
      formatter: validationButtonFunction,
      //sort: true,
      headerStyle: { minWidth: '110px' },
      sortCaret: utilTable.sortIcon,
      //sortFunc: (a,b,order) => { },
      //onSort: (field, order) => { sortFunction("button", order) },
    }
  ]
}

export default {
  //getTableIncidences,
  getTableDashboardExamns,
  getTableInitialExman,
  getTableExman,
  getTableExmanF13,
  getTableRegistry,
  //getTableEnroll,
  //getTableIncompleteEnroll,
}

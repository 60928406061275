/**
 * File to serve all function to manage data objects
 *
 * Import:
 * import db from 'utilities/utilData.jsx'
 *
 */
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import utilities from "utilities/utilities.jsx";

import moment from "moment";
moment.locale("es");

const languages = require("../assets/translate/translate_validationFormTable.json");
let lang = localStorage.lang ? localStorage.lang : "es";

async function prepareDashDataToTable(data) {
  let _result = [];
  for (let i = 0; i < data.length; i++) {
    let obj = {};

    // function limitarCaracteres(cadena, limite) {
    //   if (cadena.length > limite) {
    //     return cadena.substring(0, limite) + '...';
    //   } else {
    //     return cadena && <Tooltip placement="top" title={{data[i].subjectName>}}></Tooltip>;
    //   }
    // } 

    // var textoLimitado = limitarCaracteres(texto, limiteCaracteres);
    // console.log(textoLimitado)

    obj.key = data[i].keyProctoring;
    obj.userName = sacarComillas(data[i].userName);
    obj.file = data[i].file ? data[i].file : "-";
    obj.examType = data[i].examType ? data[i].examType : "-";
    // obj.subjectName = data[i].subjectName ? limitarCaracteres(texto, limiteCaracteres) : "-";
    obj.subjectName = data[i].subjectName ? data[i].subjectName : "-";
    obj.createdAt = data[i].createdAt ? moment(data[i].createdAt).format("DD/MM/YYYY") :data[i].created_at ? moment(data[i].created_at).format("DD/MM/YYYY") : "";
    obj.createdAtTime = data[i].createdAt ? moment(data[i].createdAt).format(moment.HTML5_FMT.TIME) :data[i].created_at ? moment(data[i].created_at).format(moment.HTML5_FMT.TIME) : "";
    obj.finalizatedAt = data[i].finalizatedAt ? moment(data[i].finalizatedAt).format(moment.HTML5_FMT.TIME) : "";
    obj.qualification = data[i].qualification ? data[i].qualification : 0;
    obj.validation = data[i].ve;
    obj.finalResultAuditory = data[i].finalResultAuditory !== null ? (data[i].finalResultAuditory === 1 ? "Validado" : data[i].finalResultAuditory === 2 ? "Aplazado" : data[i].finalResultAuditory === 0 ? "Con solic. de sanción" : "Llamado de atención") : "";

    _result.push(obj);
  }

  return _result;
}

function prepareDataGraph(data) {
  let _return = {};

  let _daysCount = data[0].day1 + data[0].day2 + data[0].day3 + data[0].day4 + data[0].day5 + data[0].day6 + data[0].day7;
  _return.daysCount = _daysCount;

  let _monthsCount = data[1].month1 + data[1].month2 + data[1].month3 + data[1].month4 + data[1].month5 + data[1].month6 + data[1].month7 + data[1].month8 + data[1].month9 + data[1].month10 + data[1].month11 + data[1].month12;
  _return.monthsCount = _monthsCount;

  let d = moment().isoWeekday();
  let m = moment().month() + 1;

  let _days = [];
  let _daysSeries = [];

  let _months = [];
  let _monthsSeries = [];

  for (let i = 1; i < 13; i++) {
    if (i < 8) {
      d++;
      if (d === 8) {
        d = 1;
      }
      processDay(d, data[0], _days, _daysSeries);
    }
    m++;
    if (m === 13) {
      m = 1;
    }
    processMonth(m, data[1], _months, _monthsSeries);
  }

  _days[6] = "Hoy";
  _months[11] = "Act";

  _return.graphData1 = { labels: _days, series: [_daysSeries] };
  _return.graphData2 = { labels: _months, series: [_monthsSeries] };

  return _return;
}

function prepateTop3Incidences(data) {
  let _return = [];
  for (let i = 0; i < 3; i++) {
    _return.push({ key: i + 1, name: data.counters[i].text, value: data.counters[i].percent });
  }

  return _return;
}

async function prepareExamnsToTable(data, section) {
  //section -> 'InitialExamn' || 'Examn'

  let dataToTable = [];

  //console.log("data")
  //console.log(data)
  const processInfo = (x, i, z) => {
    if (z) {
      let _obj = x;

      let _duration = "-";
      if (x.createdAt !== null && x.finalizatedAt !== null) {
        let _start = moment(x.createdAt);
        let _end = moment(x.finalizatedAt);
        let _diff = _end.diff(_start);
        _duration = moment.utc(_diff).format("HH:mm:ss");
      }
      _obj.duration=_duration
      _obj.finalizatedAt = x.finalizatedAt ? moment(x.finalizatedAt).format(moment.HTML5_FMT.TIME) : "";
      _obj.validation = x.ve ? x.ve : 0;
      _obj.qualification = x.qualification ? x.qualification : 0;
      _obj.grade = x.grade ? x.grade : "-";
     
      return _obj
    }
    if (true) {
      let _obj = {};

      //Para Tabla principal

      let _durationAuditory = "-";
      if (x.startOfAudit !== null && x.endOfAudit !== null) {
        let _start = moment(x.startOfAudit);
        let _end = moment(x.endOfAudit);
        let _diff = _end.diff(_start);
        _durationAuditory = moment.utc(_diff).format("HH:mm:ss");
      }

      let _duration = "-";
      if (x.createdAt !== null && x.finalizatedAt !== null) {
        let _start = moment(x.createdAt);
        let _end = moment(x.finalizatedAt);
        let _diff = _end.diff(_start);
        _duration = moment.utc(_diff).format("HH:mm:ss");
      }

      //Initial Examn & Examn
      _obj.key = i||'';
      _obj.id = x.id||'';
      _obj.ip = x.ip||'';
      _obj.attempt = x.attempt||'';
      _obj.recovery = x.recovery||'';
      _obj.examId = x.examId||'';
      _obj.userName = (x.userName ? x.userName : "-").replace(/["']/g, "");
      _obj.user = x.user ? x.user : "";
      _obj.dni = getDNI(x.userData ? x.userData : "");
      _obj.createdAtTime = x.createdAt ? moment(x.createdAt).format(moment.HTML5_FMT.TIME) : x.created_at ? moment(x.created_at).format(moment.HTML5_FMT.TIME) : "";
      _obj.finalizatedAt = x.finalizatedAt ? moment(x.finalizatedAt).format(moment.HTML5_FMT.TIME) : "";
      _obj.createdAt = x.createdAt ? moment(x.createdAt).format("DD/MM/YYYY") : x.created_at ? moment(x.created_at).format("DD/MM/YYYY"):"";
      _obj._createdAt_ = x.createdAt||'';
      _obj.validation = x.ve ? x.ve : 0;
      _obj.examStartWithTime = x.createdAt ? moment(x.createdAt).format("DD/MM/YYYY HH:mm:ss") : x.created_at ? moment(x.created_at).format("DD/MM/YYYY HH:mm:ss"): "";
      _obj.duration = _duration;

      //Examn
      let subjectName
      let examType
      if(x.course){
        subjectName=x.course.course_title ? x.course.course_title : "-"
        examType=x.course.exam_title? x.course.exam_title : "-"
      }
      else{
        subjectName= x.subjectName ? x.subjectName : "-"
        examType= x.examType ? x.examType : "-"
      }
      _obj.subjectName = subjectName
      _obj.examType =examType
      _obj.file = x.file ? x.file : "-";
      _obj.qualification = x.qualification ? x.qualification : 0;
      _obj.grade = x.grade ? x.grade : "-";
      _obj.subjectName = x.subjectName ? x.subjectName : "-";
      _obj.examType = x.examType ? x.examType : "-";

      _obj.countExam = x.countExam ? x.countExam : "-";

      _obj.auditorUser = x.auditorUser ? (x.auditorUser !== "undefined" ? x.auditorUser : "-") : "-";
      _obj.auditTime = _durationAuditory;

      _obj.finalResultAuditory = x.finalResultAuditory || x.finalResultAuditory == 0 ? x.finalResultAuditory : -1;
      _obj.finalResultAuditoryDetail = x.finalResultAuditoryDetail ? x.finalResultAuditoryDetail : "";
      _obj.keyProctoring = x.keyProctoring||'';

      //BivValidationFormProfile
      _obj.userData = x.userData ||'';

      //BivValidationFormProfileDetail
      _obj.confidence = x.confidence ? parseFloat(x.confidence).toFixed(2) + "%" : "-";

      //BivValidationFormInfoPc
      _obj.systemReport = x.systemReport||'';
      _obj.instantPhoto = x.instantPhoto||'';

      //BivValidationFormVideo
      _obj.faceVideo = x.faceVideo||'';
      _obj.desktopVideo = x.desktopVideo||'';

      //BivValidationFormTable
      _obj.incidences = getIncidences(x);

      _obj.incidencesCounters = x.incidencesCounters||'';

      _obj.version = x.version||'';

      return _obj;
    }
  };
  if (Array.isArray(data)) {
    for (let i in data) {
      let _obj = processInfo(data[i], i);
      dataToTable.push(_obj);
    }
  } else {
    return processInfo(data, data.key, true);
  }

  return dataToTable;
}

async function prepareDataToExport(data, section) {
  //section -> 'InitialExamn' || 'Dashboard' || 'Examn'

  let _return = [];

  for (let i = 0; i < data.length; i++) {
    let incidencesCounters = [];

    if (data[i].incidencesCounters !== null) {
      incidencesCounters = JSON.parse(data[i].incidencesCounters);
    } else {
      incidencesCounters = JSON.parse('{"criticCounters": {"1": 0, "2": 0, "3": 0, "4": 0}, "incidencesCounters": {"0": 0, "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0, "9": 0, "10": 0, "11": 0, "12": 0, "13": 0, "14": 0, "15": 0, "16": 0, "17": 0, "18": 0, "19": 0, "20": 0 }}');
    }

    let _duration = "-";
    if (data[i].createdAt !== null && data[i].finalizatedAt !== null) {
      let _start = moment(data[i].createdAt);
      let _end = moment(data[i].finalizatedAt);
      let _diff = _end.diff(_start);
      _duration = moment.utc(_diff).format("HH:mm:ss");
    }

    let _durationAditory = "-";
    if (data[i].startOfAudit !== null && data[i].endOfAudit !== null) {
      let _startAditory = moment(data[i].startOfAudit);
      let _endAditory = moment(data[i].endOfAudit);
      let _diffAditory = _endAditory.diff(_startAditory);
      _durationAditory = moment.utc(_diffAditory).format("HH:mm:ss");
    }

    let _ve = utilities.getValidationString(data[i].ve);

    let _finalResult = utilities.getFinalResultString(data[i].finalResultAuditory);

    let _fecha = " ";
    let _horaInicio = " ";
    if (data[i].createdAt != null) {
      _fecha = moment(data[i].createdAt)
        .startOf("day")
        .format("DD/MM/YYYY");
      _horaInicio = moment(data[i].createdAt).format("HH:mm:ss");
    }

    let _horaFin = " ";
    if (data[i].finalizatedAt != null) {
      _horaFin = moment(data[i].finalizatedAt).format("HH:mm:ss");
    }

    let _item = {};

    // id_examen
    let subjectName
    let examType
    let id_examen
    if(data[i].course){
      subjectName=data[i].course.course_title? data[i].course.course_title : "-"
      examType=data[i].course.exam_title? data[i].course.exam_title : "-"
      id_examen = data[i].course.examId ? data[i].course.examId : '-'
    }
    else{
      subjectName= data[i].subjectName ? data[i].subjectName : "-"
      examType= data[i].examType ? data[i].examType : "-"
      id_examen = data[i].examId ? data[i].examId : '-'
  }
    _item.id_examen = id_examen

    // Legajo -> Dash - Examns
    if (section !== "InitialExamn") {
      _item.legajo = data[i].file ? data[i].file : "-";
    }

    // usuario
    _item.usuario = data[i].user ? data[i].user : "-";
    // nombre
    _item.nombre_apellido = data[i].userName ? data[i].userName : "-";

    // DNI -> Examns - Initial Examns
    if (section !== "Dashboard") {
      _item.dni = getDNI(data[i].userData ? data[i].userData : "");
    }

    // fecha
    _item.fecha = _fecha;

    //cantidad de intentos ?
    _item.cantidad_intentos = data[i].countExam ? data[i].countExam.length : "-";

    // tipo_de_examen
    
    _item.tipo_de_examen = examType
    // materia
    _item.materia = subjectName

    // hora_inicio
    _item.hora_inicio = _horaInicio;
    // hora_fin
    _item.hora_fin = _horaFin;
    // duracion
    _item.duracion = _duration;

    // estado_inicial
    _item.estado_inicial = _ve;

    _item.video_desktop = data[i].desktopVideo === "" || data[i].desktopVideo === null ? languages[lang].validation_no : languages[lang].validation_yes;
    _item.video_camara = data[i].faceVideo === "" || data[i].faceVideo === null ? languages[lang].validation_no : languages[lang].validation_yes;

    // nota_provisoria
    _item.nota_provisoria = data[i].qualification ? data[i].qualification : "-";

    // nota_final -> Examns - Initial Examns
    if (section !== "Dashboard") {
      _item.nota_final = data[i].grade ? data[i].grade : "-";
    }

    // resultado_final
    _item.resultado_final = _finalResult;
    // detalle
    _item.detalle = data[i].finalResultAuditoryDetail ? data[i].finalResultAuditoryDetail : "-";

    // usuario_asesos_auditor
    _item.usuario_asesor_auditor = data[i].auditorUser ? (data[i].auditorUser !== "undefined" ? data[i].auditorUser : "-") : "-";
    // duracion_auditoria
    _item.duracion_auditoria = _durationAditory;

    // Dash
    if (section !== "Dashboard") {
      // realizacion_auditoria
      _item.realizacion_auditoria = data[i].endOfAudit ? moment(data[i].endOfAudit).format("DD/MM/YYYY HH:mm:ss") : "-";
    }

    // bajas - medias - altas - criticas
    _item.bajas = incidencesCounters.criticCounters[1] ? incidencesCounters.criticCounters[1] : 0;
    _item.medias = incidencesCounters.criticCounters[2] ? incidencesCounters.criticCounters[2] : 0;
    _item.altas = incidencesCounters.criticCounters[3] ? incidencesCounters.criticCounters[3] : 0;
    _item.criticas = incidencesCounters.criticCounters[4] ? incidencesCounters.criticCounters[4] : 0;

    // *Incidencias()
    _item.se_reconoció_a_otra_persona = incidencesCounters.incidencesCounters[0] ? incidencesCounters.incidencesCounters[0] : 0;
    _item.desvio_la_mirada = incidencesCounters.incidencesCounters[1] ? incidencesCounters.incidencesCounters[1] : 0;
    _item.se_reconocio_a_mas_de_una_persona = incidencesCounters.incidencesCounters[2] ? incidencesCounters.incidencesCounters[2] : 0;
    _item.se_detecta_una_alteracion_en_el_entorno = incidencesCounters.incidencesCounters[3] ? incidencesCounters.incidencesCounters[3] : 0;
    _item.demasiado_ruido_en_el_ambiente = incidencesCounters.incidencesCounters[4] ? incidencesCounters.incidencesCounters[4] : 0;
    _item.miro_para_abajo = incidencesCounters.incidencesCounters[5] ? incidencesCounters.incidencesCounters[5] : 0;
    _item.miro_para_arriba = incidencesCounters.incidencesCounters[6] ? incidencesCounters.incidencesCounters[6] : 0;
    _item.miro_para_un_costado = incidencesCounters.incidencesCounters[7] ? incidencesCounters.incidencesCounters[7] : 0;
    _item.no_tiene_suficiente_nivel_de_bateria = incidencesCounters.incidencesCounters[8] ? incidencesCounters.incidencesCounters[8] : 0;
    _item.se_encuentran_maquinas_virtuales_activas = incidencesCounters.incidencesCounters[9] ? incidencesCounters.incidencesCounters[9] : 0;
    _item.no_tiene_suficiente_estacio_de_almacenamiento = incidencesCounters.incidencesCounters[10] ? incidencesCounters.incidencesCounters[10] : 0;
    _item.procesos_abiertos = incidencesCounters.incidencesCounters[11] ? incidencesCounters.incidencesCounters[11] : 0;
    _item.se_encuentra_mas_de_un_monitor_conectado = incidencesCounters.incidencesCounters[12] ? incidencesCounters.incidencesCounters[12] : 0;
    _item.el_volumen_del_microfono_es_demasiado_bajo = incidencesCounters.incidencesCounters[13] ? incidencesCounters.incidencesCounters[13] : 0;
    _item.Imagen_reemplazando_la_cámara = incidencesCounters.incidencesCounters[14] ? incidencesCounters.incidencesCounters[14] : 0;
    _item.Se_detectan_otras_herramientas_en_primer_plano = incidencesCounters.incidencesCounters[15] ? incidencesCounters.incidencesCounters[15] : 0;
    _item.Se_detectan_usos_sospechosos_del_teclado = incidencesCounters.incidencesCounters[16] ? incidencesCounters.incidencesCounters[16] : 0;
    _item.La_persona_no_esta = incidencesCounters.incidencesCounters[17] ? incidencesCounters.incidencesCounters[17] : 0;
    _item.No_se_identifica_a_la_persona_empadronada = incidencesCounters.incidencesCounters[18] ? incidencesCounters.incidencesCounters[18] : 0;
    _item.Se_detectan_movimientos_de_rostro = incidencesCounters.incidencesCounters[19] ? incidencesCounters.incidencesCounters[19] : 0;
    _item.Se_reconoce_a_más_de_una_persona = incidencesCounters.incidencesCounters[20] ? incidencesCounters.incidencesCounters[20] : 0;

    _return.push(_item);
  }

  return _return;
}

export default {
  prepareDashDataToTable,
  prepareDataGraph,
  prepateTop3Incidences,
  prepareExamnsToTable,
  prepareDataToExport,
  prepareIncidences,
  getIncidences,
};

function sacarComillas(str) {
  let _str = "-";
  if (str != null) {
    if (str.length > 1) {
      if (str.substring(0, 1).toUpperCase() === '"') {
        _str = str.substring(1, str.length - 1);
      } else {
        _str = str;
      }
    }
  }
  return _str;
}

function processDay(d, data, days, daysSeries) {
  switch (d) {
    case 1:
      days.push(languages[lang].validation_m);
      daysSeries.push(data.day1);
      break;
    case 2:
      days.push(languages[lang].validation_t);
      daysSeries.push(data.day2);
      break;
    case 3:
      days.push(languages[lang].validation_w);
      daysSeries.push(data.day3);
      break;
    case 4:
      days.push(languages[lang].validation_t1);
      daysSeries.push(data.day4);
      break;
    case 5:
      days.push(languages[lang].validation_f);
      daysSeries.push(data.day5);
      break;
    case 6:
      days.push(languages[lang].validation_s);
      daysSeries.push(data.day6);
      break;
    case 7:
      days.push(languages[lang].validation_s1);
      daysSeries.push(data.day7);
      break;
    default:
      break;
  }
}

function processMonth(m, data, months, monthsSeries) {
  switch (m) {
    case 1:
      months.push(languages[lang].validation_jan);
      monthsSeries.push(data.month1);
      break;
    case 2:
      months.push(languages[lang].validation_feb);
      monthsSeries.push(data.month2);
      break;
    case 3:
      months.push(languages[lang].validation_mar);
      monthsSeries.push(data.month3);
      break;
    case 4:
      months.push(languages[lang].validation_apr);
      monthsSeries.push(data.month4);
      break;
    case 5:
      months.push(languages[lang].validation_may);
      monthsSeries.push(data.month5);
      break;
    case 6:
      months.push(languages[lang].validation_jun);
      monthsSeries.push(data.month6);
      break;
    case 7:
      months.push(languages[lang].validation_jul);
      monthsSeries.push(data.month7);
      break;
    case 8:
      months.push(languages[lang].validation_aug);
      monthsSeries.push(data.month8);
      break;
    case 9:
      months.push(languages[lang].validation_sep);
      monthsSeries.push(data.month9);
      break;
    case 10:
      months.push(languages[lang].validation_oct);
      monthsSeries.push(data.month10);
      break;
    case 11:
      months.push(languages[lang].validation_nov);
      monthsSeries.push(data.month11);
      break;
    case 12:
      months.push(languages[lang].validation_dec);
      monthsSeries.push(data.month12);
      break;
    default:
      break;
  }
}

function getDNI(data) {
  if (data !== "") {
    let _userData = JSON.parse(data);
    let _dni = _userData.d ? (_userData.d.dni ? _userData.d.dni : "") : "";
    return _dni;
  } else {
    return data;
  }
}

function getIncidences(data) {
  //Para Tabla de incidencias
  let _incidendeObj = [];

  if (data.incidences) {
    let _incidences = JSON.parse(data.incidences);

    for (let e in _incidences) {
      let _incidence = {};
      _incidence.number = 1;
      _incidence.key = 1;
      _incidence._key = _incidences[e].type;

      _incidence.anomaly = _incidences[e].detail ? _incidences[e].detail : "-";

      _incidence.type = _incidences[e].criticality ? getCriticalText(_incidences[e].criticality) : "-";

      if (_incidences[e].origin) {
        if (_incidences[e].origin === 1) {
          _incidence.origin = "Imagen";
        } else if (_incidences[e].origin === 2) {
          _incidence.origin = "";
        }
      }

      if (_incidences[e].time) {
        let start = moment(data._createdAt_);
        let end = moment(parseInt(_incidences[e].time));
        let diff = _incidences[e].time && parseInt(_incidences[e].time) < 1000000000000 ? parseInt(_incidences[e].time) : end.diff(start);
        let f = moment.utc(diff).format("HH:mm:ss");
        _incidence.time = f.toString() + " hs";
        _incidence.diff = diff;
        _incidence.button = diff;
      }

      _incidendeObj.push(_incidence);
    }
  } else {
    if (data.tdi) {
      processValidationInsidence(data.tdi, data.createdAt, _incidendeObj);
    }

    if (data.tui) {
      processValidationInsidence(data.tui, data.createdAt, _incidendeObj);
    }

    if (data.tlri) {
      processValidationInsidence(data.tlri, data.createdAt, _incidendeObj);
    }

    if (data.fri) {
      processValidationInsidence(data.fri, data.createdAt, _incidendeObj);
    }

    if (data.nfi) {
      processValidationInsidence(data.nfi, data.createdAt, _incidendeObj);
    }

    if (data.mfi) {
      processValidationInsidence(data.mfi, data.createdAt, _incidendeObj);
    }

    if (data.sci) {
      processValidationInsidence(data.sci, data.createdAt, _incidendeObj);
    }
  }

  _incidendeObj = _incidendeObj.sort((a, b) => a.diff - b.diff);

  let _num = 1;
  for (let f in _incidendeObj) {
    _incidendeObj[f].number = _num;
    _incidendeObj[f].key = _num;
    _num++;
  }

  return _incidendeObj;
}

function prepareIncidences(data) {
  let _incidenceLow = [];
  let _incidenceMedium = [];
  let _incidenceHigh = [];
  let _incidenceCritic = [];

  for (let i = 0; i < data.length; i++) {
    switch (data[i].type) {
      case "Baja":
        _incidenceLow.push(data[i]);
        break;
      case "Media":
        _incidenceMedium.push(data[i]);
        break;
      case "Alta":
        _incidenceHigh.push(data[i]);
        break;
      case "Crítica":
        _incidenceCritic.push(data[i]);
        break;
      default:
        break;
    }
  }

  let _return = {
    incidenceLow: _incidenceLow,
    incidenceMedium: _incidenceMedium,
    incidenceHigh: _incidenceHigh,
    incidenceCritic: _incidenceCritic,
  };

  return _return;
}

function getCriticalText(_data) {
  switch (_data) {
    case 1:
      return languages[lang].validation_low;
    case 2:
      return languages[lang].validation_medium;
    case 3:
      return languages[lang].validation_high;
    case 4:
      return languages[lang].validation_critic;
    default:
      return "-";
  }
}

function processValidationInsidence(data, createdAt, pushTo) {
  let _data = JSON.parse(data);

  for (let o in _data) {
    let obj = {};
    obj.number = 1;
    obj._key = _data[o].type;
    obj.anomaly = _data[o].d;
    obj.origin = "Imagen";
    obj.type = _data[o].c ? getCriticalText(_data[o].c) : "-";

    if (_data[o].t) {
      let start = moment(createdAt);
      let end = moment(parseInt(_data[o].t));
      let diff = end.diff(start);
      let f = moment.utc(diff).format("HH:mm:ss");
      obj.time = f.toString() + " hs";
      obj.diff = f.toString() + " hs";
      obj.button = diff;
    }
    pushTo.push(obj);
  }
}

/*
id
finalizatedAt
userName
userData
createdAt
finalizatedAt
ve
file
qualification
grade
subjectName
examType
finalResultAuditory
finalResultAuditoryDetail
keyProctoring
confidence
systemReport
instantPhoto

faceVideo
desktopVideo

incidences

tdi
tui
tlri
fri
nfi
mfi
sci
 */

import React from 'react'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import IconButton from '@material-ui/core/IconButton'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

import Collapse from '@material-ui/core/Collapse'

import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
//import NativeSelect from '@material-ui/core/NativeSelect';
import MenuItem from "@material-ui/core/MenuItem"
import Autocomplete from '@material-ui/lab/Autocomplete'
import db from "db/db.jsx"

import BivButtonFind from 'components/BivButtonFind/BivButtonFind.jsx'

import ClearIcon from '@material-ui/icons/Clear'

import moment from "moment"

import Button from "components/CustomButtons/Button.jsx"

import utilities from 'utilities/utilities.jsx'
import {FormControl } from '@material-ui/core'

moment.locale("es")


const languages = require('../../assets/translate/translate_bivProctoringFilter.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

class BivProctoringFilter extends React.Component {
  constructor(props) {
    super(props)
    this.fields = global.exam_table_fields
    this.state = {

      //Show/Hide search filters
      showSearch: false,

      //Store user
      searchUser: "",

      subjects: null,
      typeOfExams: null,
      audited: null,
      examnsCanvas: null,
      searchExamCanvas:null
    }
  }

  findfunc() {
    //this.setState({ pageNumber: 1 }, () => {
    this.props.showLoading(true)
    this.getExamns()
    //})
  }
  getExamnsCanvas(x) {
    let myHeaders = new Headers();
    myHeaders.append("Cookie", "adonis-session=8042b935754ae0e6d55eb024acf210a1Ve9o9qNQTF18D8TbH7yUW4XmNK8YoKRo3CAA%2F7Fpl0LHQFGJ6nQQSkVn7Mp7nDREYwY5ru6ZluVeNH0779mR77jd6yN5jp6WFIeWBFBDD84SdhEb8BJ6JX8TkLRSud0v; adonis-session-values=97d76c61daecef7de6243400ac2b82daaF5EHMv9Y3IuuK85y3UJ26LO%2FYk6%2BPRqdfJluncICtx57i4EQICoqhFnxuyU%2Buv29ge38zVwsrewBivcZGFdFQ%3D%3D");

    let formdata = new FormData();
    formdata.append("courseId", x.id
    );

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(global.Direction_Back_panel + "/v2/validations/canvas/getExam", requestOptions)
      .then(response => response.text())
      .then(result => {
        let _res = JSON.parse(result)
        this.setState({ examnsCanvas: _res.data })
      })
      .catch(error => console.log('error', error));
  }

  componentDidMount() {
    //this.getExamns()
  }
  componentDidUpdate() {
    // if (this.props.forceLoadData === true) {
    //  // this.props.updateVariablesOff()
    //   this.props.research()
    // }
    /* 
        if (this.props.pageNumber !== this.state.pageNumber) {
          this.setState({
            pageNumber: this.props.pageNumber
          }, () => {
            this.props.updateVariablesOff()
            this.getExamns()
          })
        }
      
        if (this.props.toExport === true) {
          this.props.updateVariablesOff()
          //this.getExamnsToExport()
          if (!this.state.exporting) {
            this.setState({ exporting: true })
            this.getExamnsToLink()
    
          }
        }
        if (this.props.orderChange === true) {
          this.props.updateVariablesOff()
          this.getExamns()
        }
    */
  }

  async loadValidationsNew() {
    let _result = await db.getExamns(
      this.state.searchElectronicValidation,
      this.state.searchExamType,
      this.state.searchUserName,
      this.state.searchSubject,
      this.state.searchStartDate,
      this.state.searchEndDate,
      this.state.searchdni,
      0,
      this.state.pageNumber,
      this.state.searchNickName,
    )

    this.processData(_result)

  }

  render() {
    //Types for validation selec
    const typesOfValidation = [
      {
        value: "Baja",
        label: languages[lang].filter_low,
      },
      {

        value: "Media",
        label: languages[lang].filter_medium,
      },
      {
        value: "Alta",
        label: languages[lang].filter_high,
      },
      {
        value: "Crítica",
        label: languages[lang].filter_critic,
      },
      {
        value: "Sin validación",
        label: languages[lang].filter_withoutValidation,
      },
      {
        value: "Sin evidencia",
        label: languages[lang].filter_noValidation,
      },
      {
        value: "Todas",
        label: languages[lang].filter_all,
      },
    ]
  
    //const getSubjects = JSON.parse(this.state.subjects)

    return (

      <div>
        <GridContainer >
          {/* Start/End date */}
          <GridItem xs={12} sm={6} md={6} lg={3} style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>


            <TextField
              style={{ width: "100%" }}
              id="searchStartDate"
              label={languages[lang].filter_from}
              type="date"
              variant="outlined"
              size="small"
              margin="dense"
              color="secondary"
              disabled={!this.fields.createdAt}
              value={this.props.searchStartDate}
              onKeyPress={
                (ev) => {
                  //utilities.elEnter(ev, this.findfunc)
                  utilities.elEnter(ev, this.props.search)
                }}
              onChange={(x) => {
                //this.setState({searchStartDate: x.target.value})
                this.props.changeStartDate(x.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3} style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
            <TextField
              style={{ width: "100%" }}
              id="searchEndDate"
              disabled={!this.fields.finalizatedAt}
              label={languages[lang].filter_to}
              type="date"
              variant="outlined"
              size="small"
              margin="dense"
              color="secondary"
              value={this.props.searchEndDate}
              onKeyPress={
                (ev) => {
                  //utilities.elEnter(ev, this.findfunc)
                  utilities.elEnter(ev, this.props.search)
                }}
              onChange={(x) => {
                //this.setState({ searchEndDate: x.target.value })
                this.props.changeEndDate(x.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={4} md={4} lg={3}>
          {this.state.showSearch ?
            <div>
            <IconButton
            disableRipple={true}

            onClick={() => {
              this.setState({ showSearch: !this.state.showSearch })
            }}
              id="search"
              className='nohoverbtn'
              style={{ outline: "none", marginRight: "0px", color: "inherit",}}
              aria-expanded={this.state.showSearch}
              aria-label="show more"
            >
             <h4 style={{ fontSize: 14 }}>Menos filtros</h4> <ExpandLess /> 
            </IconButton>
            </div>
            :
            <div>
            <IconButton
            disableRipple={true}
            onClick={() => {
              this.setState({ showSearch: !this.state.showSearch })
            }}
              id="search"
              className='nohoverbtn'
              style={{ outline: "none", marginRight: "0px", color: "inherit"}}
              aria-expanded={this.state.showSearch}
              aria-label="show more"
            >
             <h4 style={{ fontSize: 14 }}>{languages[lang].filter_more}</h4> < ExpandMoreIcon /> 
            </IconButton>
            </div>
            }
          </GridItem>

          {/* <GridItem xs={12} sm={4} md={4} lg={3} onClick={() => {
            this.setState({ showSearch: !this.state.showSearch })
          }} style={{ display: "flex", alignItems: "center", paddingLeft: 5 }}>
            <h4 style={{ fontSize: 14 }}>Filtros</h4>

            <IconButton
              id="search"
              style={{ outline: "none", marginRight: "0px" }}

              aria-expanded={this.state.showSearch}
              aria-label="show more"
            >
              {this.state.showSearch ? <ExpandLess /> : < ExpandMoreIcon />}
            </IconButton>
          </GridItem> */}

          <GridItem xs={12} sm={5} md={5} lg={1}> </GridItem>

          <GridItem xs={12} sm={3} md={3} lg={2} style={{ width: '100%', marginTop: 20, display: 'flex', justifyContent: "flex-end", }}>
            <div style={{ maxWidth: 148, width: "100%" }}>
              <BivButtonFind
                onClick={() => {
                  this.props.search()
                }}
              />
            </div>
          </GridItem>

          <GridItem xs={12} >
            <div style={{ display: "flex" }}>


            </div>

            <Collapse in={this.state.showSearch} timeout="auto" unmountOnExit>

              <CardContent style={{ padding: 0 }}>


                <GridContainer style={{ padding: 0 }}>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5, marginBottom: 5 }}>
                    <div style={{ width: "100%", height: 5, borderTop: '1px solid #ced4db' }} />
                  </GridItem>
                </GridContainer>

                <GridContainer style={{ padding: 0 }}>

                  {/* Electronic validation - select */}

                  {/* UserName - text */}
                  <GridItem xs={12} sm={6} md={global._lms_ === "4" ? 4 : 3} style={{ display: "flex", alignItems: "center" }}>
                    <TextField

                      style={{ width: '100%' }}
                      id="searchUserName"
                      disabled={!this.fields.userName.enable}
                      label={ this.fields.userName.name}
                      variant="outlined"
                      size="small"
                      margin="dense"
                      color="secondary"
                      value={this.props.searchUserName}
                      onKeyPress={
                        (ev) => {
                          //utilities.elEnter(ev, this.findfunc)
                          utilities.elEnter(ev, this.props.search)
                        }}
                      onChange={(x) => {
                        //this.setState({ searchUserName: x.target.value })
                        this.props.changeUserName(x.target.value)
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={global._lms_ === "4" ? 4 : 3} style={{ display: "flex", alignItems: "center" }}>
                    <TextField

                      style={{ width: '100%' }}

                      id="searchdni"
                      disabled={!this.fields.dni}
                      label={global.dni_name}
                      variant="outlined"
                      size="small"
                      margin="dense"
                      color="secondary"
                      value={this.props.searchdni}
                      onKeyPress={
                        (ev) => {
                          //utilities.elEnter(ev, this.findfunc)
                          utilities.elEnter(ev, this.props.search)
                        }}

                      onChange={(x) => {
                        //this.setState({ searchdni: x.target.value })
                        this.props.changedni(x.target.value)
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={global._lms_ === "4" ? 4 : 3} style={{ display: "flex", alignItems: "center" }}>
                    <TextField

                      style={{ width: '100%' }}

                      id="searchNickName"
                      label={this.fields.user.name}
                      disabled={!this.fields.user.enable}
                      variant="outlined"
                      size="small"
                      margin="dense"
                      color="secondary"
                      value={this.props.searchNickName}
                      onKeyPress={
                        (ev) => {
                          //utilities.elEnter(ev, this.findfunc)
                          utilities.elEnter(ev, this.props.search)
                        }}

                      onChange={(x) => {
                        //this.setState({ searchNickName: x.target.value })
                        this.props.changeNickName(x.target.value)
                      }}
                    />
                  </GridItem>

                  {/* File - text */}
                  {global._lms_ === "4" ? null :
                    <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", alignItems: "center" }}>
                      <TextField

                        style={{ width: '100%' }}

                        id="searchfile"
                        label={this.fields.file.name}
                        disabled={!this.fields.file.enable}
                        // type="number"
                        variant="outlined"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={this.props.searchfile}
                        onKeyPress={
                          (ev) => {
                            //utilities.elEnter(ev, this.findfunc)
                            utilities.elEnter(ev, this.props.search)
                          }}
                        onChange={(x) => {
                          //this.setState({ searchfile: x.target.value })
                          this.props.changefile(x.target.value)
                        }}
                      />
                    </GridItem>
                  }
                </GridContainer>

                <GridContainer>
                  {/* Materia 
                  <GridItem xs={6} sm={6} md={6} lg={3} xl={3}>
                    <form noValidate autoComplete="off">
                      <TextField
                        style={{ width: "100%" }}
                        id="searchSubject"
                        select
                        variant="outlined"
                        label="Materia"
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={this.state.searchSubject}
                        onChange={(event) => {
                          this.setState({ searchSubject: event.target.value });
                        }}
                      >
                        {typesOfValidation.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </form>
                  </GridItem>*/}

                  {global._lms_ === "4" ?
                    <GridItem xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: "flex", alignItems: "center" }}>
                      <FormControl style={{ width: '100%' }}  >


                        <Autocomplete
                          id="searchSubjectCanvas"
                          // multiple={true}
                          limitTags={2} 
                          disabled={!this.fields.subjectName.enable}
                          label={this.fields.subjectName.name}
                          style={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label={this.fields.subjectName.name} variant="outlined" />}
                          options={this.props.canvasSubjects ? this.props.canvasSubjects : [languages[lang].filter_noOptionsAvailable]}
                          getOptionLabel={(option) => option.title?option.title:''}
                          size="small"
                          value={this.props.searchCanvasSubject}
                          onChange={(x, y, z) => {
                            //this.setState({ searchSubject: y })
                            if (y) {
                              this.getExamnsCanvas(y)

                            }
                            else {
                              this.setState({ examnsCanvas: null })

                            }
                            this.setState({ searchExamCanvas: null});
                            this.props.changeSubject(y)
                            this.props.changeExamCanvas(null)
                          }}
                        />

                      </FormControl >
                    </GridItem>
                    :
                    <GridItem xs={12} sm={6} md={9} lg={9} xl={9} style={{ display: "flex", alignItems: "center" }}>
                      {/* New Select
                    <form noValidate autoComplete="on">*/}

                      <FormControl style={{ width: '100%' }}  >


                        <Autocomplete
                          id="searchSubject"
                          disabled={!this.fields.subjectName.enable}

                          multiple={true}
                          limitTags={2}
                          label={this.fields.subjectName.name}
                          style={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label={this.fields.subjectName.name} variant="outlined" />}
                          options={this.props.subjects ? this.props.subjects : [languages[lang].filter_noOptionsAvailable]}
                          size="small"
                          value={this.props.searchSubject}
                          onChange={(x, y, z) => {
                            this.props.changeSubject(y)
                            // this.setState({ searchSubject: y })
                          }} 
                        />

                      </FormControl >

                    </GridItem>
                  }

                  <GridItem xs={12} sm={6} md={global._lms_ === "4" ? 6 : 3} style={{ display: "flex", alignItems: "center" }}>
                    <FormControl
                      style={{ width: '100%' }}
                      noValidate autoComplete="off">
                      <TextField
                        style={{ width: '100%' }}

                        id="searchElectronicValidation"
                        disabled={!this.fields.validation.enable}

                        select
                        variant="outlined"
                        label={this.fields.validation.name}
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={this.props.searchElectronicValidation}
                        onChange={(event) => {
                          //this.setState({ searchElectronicValidation: event.target.value });
                          this.props.changeElectronicValidation(event.target.value)
                        }}
                      >
                        {typesOfValidation.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </GridItem>

                  {/* Exam type - select */}
                  {global._lms_ === "4" ? <GridItem xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: "flex", alignItems: "center" }}>
                    <FormControl
                      style={{ width: '100%' }}
                    >


                      <Autocomplete
                        id="searchExamType"
                        disabled={!this.fields.examType.enable}

                        // limitTags={2}
                        label={this.fields.examType.name}
                        style={{ width: '100%' }}
                        // multiple={true}
                        renderInput={(params) => <TextField {...params} label={"Examen"} variant="outlined" />}
                        options={this.state.examnsCanvas ? this.state.examnsCanvas : [{ exam_title: 'Seleccione un curso para habilitar este flitro', noselect: true }]}
                        size="small"
                        value={this.state.searchExamCanvas}
                        getOptionLabel={(option) => option.exam_title?option.exam_title:''}
                        onChange={(event, x) => {
                          if (!x || x.noselect) {
                            this.setState({ searchExamCanvas: null });
                          }
                          else {
                            this.setState({ searchExamCanvas: x });
                            this.props.changeExamCanvas(x)
                          }
                        }}
                      />

                    </FormControl >
                  </GridItem> :

                    <GridItem xs={12} sm={5} md={5} lg={5} xl={5} style={{ display: "flex", alignItems: "center" }}>
                      <FormControl
                        style={{ width: '100%' }}
                      >


                        <Autocomplete
                          id="searchExamType"
                          disabled={!this.fields.examType.enable}

                          limitTags={2}
                          label={this.fields.examType.name}
                          style={{ width: '100%' }}
                          multiple={true}
                          renderInput={(params) => <TextField {...params} label={this.fields.examType.name} variant="outlined" />}
                          options={this.props.typeOfExams ? this.props.typeOfExams : []}
                          size="small"
                          value={this.props.searchExamType}
                          onChange={(event, x) => {
                            //this.setState({ searchExamType: x });
                            this.props.changeExamType(x)
                          }}
                        />

                      </FormControl >
                    </GridItem>
                  }
                  {/* Audit - unaudit */}
                  <GridItem xs={12} sm={4} md={global._lms_ === "4" ? 3 : 4} lg={4} xl={4} style={{ display: "flex", alignItems: "center" }}>
                    <FormControl
                      style={{ width: '100%' }}
                      noValidate autoComplete="off">
                      <TextField
                        style={{ width: '100%' }}

                        id="searchAudit"

                        select
                        variant="outlined"
                        label={languages[lang].filter_audited}
                        size="small"
                        margin="dense"
                        color="secondary"
                        value={this.props.searchAudit}
                        onChange={(event) => {
                          this.props.changeAudit(event.target.value)
                          // this.setState({ searchAudit: event.target.value });
                        }}
                      >
                        {([{
                          value: true,
                          label: languages[lang].filter_audited1,
                        },
                        {
                          value: false,
                          label: languages[lang].filter_unaudited,
                        },
                        {
                          value: "all",
                          label: languages[lang].filter_all2,
                        }]).map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </GridItem>


                  <GridItem xs={12} sm={6} md={3} lg={3} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

                    <Button
                      round
                      disabled={(!this.props.searchExamType || this.props.searchExamType === "Todos") && (!this.props.searchElectronicValidation || this.props.searchElectronicValidation === "Todas") && (!this.props.searchfile || this.props.searchfile === "") && (!this.props.searchSubject || this.props.searchSubject === "") && (!this.props.searchNickName || this.props.searchNickName === "") && (!this.props.searchdni || this.props.searchdni === "") && (!this.props.searchUserName || this.props.searchUserName === "")}
                      endIcon={<ClearIcon />}
                      style={{ width: "100%", maxWidth: 150 }}
                      color="#B5C3AE"
                      onClick={() => {
                        this.setState({examnsCanvas: null })
                        this.setState({ searchExamCanvas: null});
                        this.props.resetFields()
                        
                      }}
                    >{languages[lang].filter_cleanF}</Button>

                  </GridItem>

                </GridContainer>
              </CardContent>
            </Collapse>

          </GridItem>

        </GridContainer>

      </div>
    )

  }

}


export default withStyles(sweetAlertStyle)(BivProctoringFilter)

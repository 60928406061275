/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import LOGO from 'assets/img/logo.png';
import LogoMin from 'assets/img/logomin.png';
import Tooltip from '@material-ui/core/Tooltip';
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import avatar from "assets/img/faces/avatar.jpg";
import firebase from "firebase";
import { variables } from "variables/variables";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, Typography } from "@mui/material";

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockIcon from '@mui/icons-material/Lock';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

var ps;
// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  state = {
    expanded: false
  }
  componentDidMount() {

    let data = JSON.stringify(localStorage.rol)
    this.__user = data.name
    this.__rol = data.authorities && data.authorities.rol || ''
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: (this.state.expanded == panel ? false : panel) });
  };
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  constructor(props) {
    super(props)
  }
  render() {
    const { className, user, headerLinks, links, miniActive } = this.props;
    const data = JSON.parse(localStorage.rol)
    const __user = data.name
    let splittedUser = data.name.split(' ')
    let firstsLetters = ''
    if (splittedUser[0]) {
      firstsLetters += splittedUser[0][0]
    }
    if (splittedUser[1]) {
      firstsLetters += splittedUser[1][0]
    }
    const __rol = data.authorities && data.authorities.rol || ''


    return (
      <div className={className} style={{ position: "relative", height: "100%",display:"flex",flexDirection:"column",justifyContent:"space-between" }} ref={this.sidebarWrapper}>
        <div style={{overflowY:"scroll",backgroundColor:"#333",overflowX:"hidden"}}>
          {user}
          {headerLinks}
          {links}
        </div>
        <Box sx={{ 
          // position: "absolute", bottom: '10px',
           padding: '10px', flexDirection: "column", display: "flex",
            width: "100%", }}>
          <Accordion style={{ backgroundColor: this.state.expanded === 'panel1' ? '#545454' : 'transparent', borderRadius: "5px", flexDirection: "row", display: "flex", padding: '20px', paddingLeft: "12px", overflow: "hidden" }} expanded={this.state.expanded === 'panel1'} onChange={this.handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              style={{ display: "none" }}
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <Box style={{ padding: 0 }}>
                <Button variant="text" style={{ display: "flex", flexDirection: "row", paddingBottom: "10px" }} onClick={() => {
                  this.handleChange('panel1')
                  document.getElementById('itemPass').querySelector('a').click()
                }}>
                  <Box sx={{}}>
                    <LockIcon style={{ color: "white" }}  ></LockIcon>
                  </Box>
                  <Box sx={{ paddingLeft: "16px", minWidth: "fit-content" }}>
                    <Typography color={'white'} variant="subtitle2" style={{ margin: 0, textWrap: "nowrap", textTransform: "none" }} component={'p'} >Cambiar contraseña</Typography>
                  </Box>
                </Button>
                <Button variant="text" style={{ display: "flex", flexDirection: "row", paddingTop: '10px' }} onClick={() => {
                  if (localStorage.from === 'siglo21') {
                    global._logoutSiglo21()
                  } else if (localStorage.from === 'lms') {
                    localStorage.from = "-"
                    localStorage.clear();
                    window.location.reload();
                  }
                  else {
                    localStorage.from = "-"
                    localStorage.clear();
                    firebase.auth().signOut().then(function () {
                      //console.log('se cerro la secion')
                      window.location.reload();

                    }).catch(function (error) {
                      //console.log('ERROR no se puede iniciar la ssecion')
                      window.location.reload();

                    });
                  }
                }}>
                  <Box sx={{}}>
                    <ExitToAppIcon style={{ color: "white" }} ></ExitToAppIcon>
                  </Box>
                  <Box sx={{ paddingLeft: "16px", minWidth: "fit-content" }}>
                    <Typography color={'white'} variant="subtitle2" style={{ margin: 0, textTransform: "none" }} component={'p'} >Cerrar sesión</Typography>
                  </Box>
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Box sx={{ backgroundColor: '#545454', borderRadius: "5px", flexDirection: "row", display: "flex", padding: '10px', overflow: "hidden" }} >
            <Box sx={{ backgroundColor: "white", borderRadius: '50%', width: "40px", height: "40px", minWidth: "40px", maxHeight: "40px", verticalAlign: "middle", textAlign: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
              <p style={{ color: "#009efd", fontSize: "15px", textTransform: "uppercase", fontWeight: "900", margin: 0 }}>{firstsLetters}</p>
            </Box>
            <Box sx={{ paddingLeft: "10px", width: '66%' }}>
              <Tooltip title={__user} >
                <Typography variant="subtitle2" component={'p'} style={{ cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}  >{__user}
                </Typography>
              </Tooltip>
              <Typography variant="body2" style={{ cursor: "pointer" }}>{__rol}</Typography>
              {/* <Typography variant="subtitle2" component={'p'} >{__user}</Typography> */}
            </Box>
            <IconButton style={{ outline: "none" }} onClick={this.handleChange('panel1')}>
              {
                this.state.expanded === "panel1" ?
                  <ExpandLessIcon style={{ color: "#009efd", cursor: "pointer" }}></ExpandLessIcon>
                  :
                  <ExpandMoreIcon style={{ color: "#009efd", cursor: "pointer" }}></ExpandMoreIcon>
              }
            </IconButton>
          </Box>
        </Box>

      </div>
    );
  }
}

class Sidebar extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes)
    };
  }
  mainPanel = React.createRef();
  handleCloseProfile = () => {
    //console.log('localStorage.from ajajsjajaja', localStorage.from)
    if (localStorage.from === 'siglo21') {
      global._logoutSiglo21()
    } else if (localStorage.from === 'lms') {
      localStorage.from = "-"
      localStorage.clear();
      this.setState({ openProfile: false });
      window.location.reload();
    }
    else {
      localStorage.from = "-"
      localStorage.clear();
      firebase.auth().signOut().then(function () {
        //console.log('se cerro la secion')
        window.location.reload();

      }).catch(function (error) {
        //console.log('ERROR no se puede iniciar la ssecion')
        window.location.reload();

      });
      this.setState({ openProfile: false });
    }
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  createLinks = routes => {
    let rol = localStorage.rol

    try {
      rol = JSON.parse(rol)
    }
    catch (_e) {
      rol = {}
    }
    const _admin = rol.admin
    rol = rol && rol.authorities && rol.authorities.permission ? rol.authorities.permission : {}
    const { classes, color, rtlActive } = this.props;
    return routes.map((prop, key) => {

      if (prop.componentNumber == 0) {
        // dashboard
        let value = rol.seeDashboard == 1 ? true : false
        if (value === false) {
          return null
        }
      }
      else if (prop.componentNumber == 1) {
        // proctorings
        let value = rol.seeExamns == 1 ? true : false
        if (value === false) {
          return null
        }
      }
      else if (prop.componentNumber == 2) {
        // Configuraciones
        // let value = rol.editSettings == 1 || rol.seeRol == 1 || rol.editUsers == 1 ? true : false
        // if (value === false) {
        //   return null
        // }
      }
      else if (prop.componentNumber == 11) {
        // Configuraciones proctoring
        let value = rol.editProctoringSettings ? true : false
        if (value === false) {
          return null
        }
      }
      else if (prop.componentNumber == 12) {
        // Configuraciones ambiente
        let value = rol.editEnviromentSettings ? true : false
        if (value === false) {
          return null
        }
      }
      else if (prop.componentNumber == 13) {
        // Configuraciones ambiente
        let value = rol.editEnviromentSettings ? true : false
        if (value === false) {
          return null
        }
      }
      else if (prop.componentNumber == 3 ) {
        return null
        // registros
        let value = roles.filter((a) => { if (a === admin || a === admin2 || a == User_Biv_Admin || a === lector || a === lector2 || a == userNoConf) { return true } }).length > 0
        if (value === false) {
          return null
        }
      }
      else if (prop.componentNumber == 4) {
        // empadronamientos
        let value = rol.seeEnrolls == 1 ? true : false
        if (value === false || JSON.parse(localStorage.siglocanvas)) {
          return null
        }
      }
      else if (prop.componentNumber == 8) {
        // roles
        let value = _admin ? true : false
        if (value === false) {
          return null
        }
      }
      else if (prop.componentNumber == 7) {
        // usuarios
        let value = _admin ? true : true
        if (value === false) {
          return null
        }
      }
      else if (prop.componentNumber == 6 ) {
        // empadronamientos
        if (!global._lms || global._lms !== "4" ) { return null }
      }


      if (prop.invisible) return null;
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            )
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive
          });
        return (

          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" && !prop.iconImg ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : prop.iconImg ? (
                  <img src={prop.icon} />
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                prop.iconImg ? (
                  <img src={prop.icon} />
                ) :
                  <span className={collapseItemMini}>
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
              )}
              <Tooltip title={prop.message} placement="top-end">
                <ListItemText
                  primary={rtlActive ? prop.rtlName : prop.name}
                  secondary={
                    <b
                      className={
                        caret +
                        " " +
                        (this.state[prop.state] ? classes.caretActive : "")
                      }
                    />

                  }
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </Tooltip>
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <Tooltip title={prop.message} placement="top-end">
                <List className={classes.list + " " + classes.collapseList}>
                  {this.createLinks(prop.views)}
                </List>
              </Tooltip>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive
        });
      if (prop.closeSession) {
        return (
          <ListItem
            key={key}
            onClick={() => { this.handleCloseProfile() }}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to="#"
              className={
                classes.itemLink
              }
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" && !prop.iconImg ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : prop.iconImg ? (
                  <img src={prop.icon} />
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                prop.iconImg ? (
                  <img src={prop.icon} />
                ) :
                  <span className={collapseItemMini}>
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
              )}

              <Tooltip title={prop.message} placement="top-end">
                <ListItemText
                  primary={rtlActive ? prop.rtlName : prop.name}
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </Tooltip>
            </NavLink>

          </ListItem>


        )
      }
      return (

        <ListItem
          key={key}
          style={prop.path === "/changepass" ? { visibility: "hidden" } : {}}
          id={prop.path === "/changepass" ? "itemPass" : null}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >

          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" && !prop.iconImg ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : prop.iconImg ? (
                <img className={itemIcon} src={prop.icon} />
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              prop.iconImg ? (
                <img className={itemIcon} src={prop.icon} />
              ) :
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
            )}
            <Tooltip title={prop.message} placement="top-end">
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </Tooltip>
          </NavLink>

        </ListItem>

      );
    });
  }


  render() {

    const {
      classes,
      routes,
      bgColor,
      rtlActive
    } = this.props;

    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      });

    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive
      });

    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });

    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive
      });

    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive
      });

    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive
      });

    var user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..." />
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <NavLink
              to={"#"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={rtlActive ? "تانيا أندرو" : "Tania Andrew"}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      classes.userCaret +
                      " " +
                      (this.state.openAvatar ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? "مع" : "MP"}
                    </span>
                    <ListItemText
                      primary={rtlActive ? "ملفي" : "My Profile"}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? "هوع" : "EP"}
                    </span>
                    <ListItemText
                      primary={
                        rtlActive ? "تعديل الملف الشخصي" : "Edit Profile"
                      }
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? "و" : "S"}
                    </span>
                    <ListItemText
                      primary={rtlActive ? "إعدادات" : "Settings"}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );

    var links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive
      });

    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive
      });

    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });

    var brand = (
      <div className={logoClasses} style={{ textAlign: 'center' }}>
        {(this.props.miniActive === true) ?
          <div
            href="#"
            target="_blank"
            className={logoMini}
            style={{ display: 'flex', justifyContent: "end", alignItems: 'center', marginTop: 6 }}
          >
            <img src={LogoMin} alt="logoMin" className={classes.img} />
          </div>
          :
          <div
            style={{
              display: 'flex',
              justifyContent: "center",
              alignItems: 'center',
              marginTop: 0,
              height: '100px',
            }}>
            <img src={LOGO} alt="LogoBig" className={classes.img} style={{ height: '94px', width: '132px' }} />
          </div>
        }
      </div>
    );

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });

    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });

    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              // user={user}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
              miniActive={this.state.miniActive || this.props.open}

              changePWD={() =>
                this.activeRoute('/changepass')
              }
            />

            {/* {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null} */}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              // user={user}
              miniActive={this.state.miniActive || this.props.open}
              links={links}
              changePWD={() =>
                this.activeRoute('/changepass')
              }
            />

            {/* {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null} */}

          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "lightBlueKW",

  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object
};

export default withStyles(sidebarStyle)(Sidebar);

import React from 'react'

import Button from "components/CustomButtons/Button.jsx"

import cellEditFactory from 'react-bootstrap-table2-editor'

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import BootstrapTable from 'react-bootstrap-table-next'

import utilTable from "utilities/utilTable.jsx"

import moment from 'moment'
moment.locale("es");

const languages = require('../../assets/translate/translate_validationFormTable.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

function KWValidationFormTable(props) {

  const {
    incidencesToExamine,
    videoGoButton,
    scrolleable,
  } = props

  KWValidationFormTable.defaultProps = {
    incidencesToExamine: [],
    videoGoButton: () => null,
    scrolleable: false,
  }

  const formatWithIconSee = (cell, row) => {

    return (
      <Button
        style={{ marginTop: 0, marginBottom: 0, paddingTop: 5, paddingBottom: 5 }}
        color="info"
        id='goTo'
        onClick={() => {
          videoGoButton(row.button)
        }}
        round
      >
        {languages[lang].validation_goTo}
      </Button>
    )
  }

  let columns = [
    {
      dataField: 'number',
      text: languages[lang].validation_n,
      editable: false,
      headerStyle: { width: '50px', textAlign: 'center', marginTop: 0, marginBottom: 0, paddingTop: 5, paddingBottom: 5 },
      style: utilTable.styleHeaderIncidences(),
    }, {
      dataField: 'anomaly',
      text: languages[lang].validation_anomaly,
      editable: false,
      headerStyle: utilTable.styleHeaderIncidences(),
      style: utilTable.styleHeaderIncidences(),
    }, {
      dataField: 'origin',
      text: languages[lang].validation_source,
      hidden: true,
      editable: false,
      headerStyle: utilTable.styleHeaderIncidences(),
      style: utilTable.styleHeaderIncidences(),
    }, {
      id:'__type',
      dataField: 'type',
      text: languages[lang].validation_type,
      editable: false,
      headerStyle: utilTable.styleHeaderIncidences(),
      style: utilTable.styleHeaderIncidences(),
    }, {
      dataField: 'time',
      text: languages[lang].validation_weather,
      editable: false,
      style: utilTable.styleHeaderIncidences(),
      headerStyle: utilTable.styleHeaderIncidences(),
    }, {
      dataField: 'detail',
      text: languages[lang].validation_detail,
      hidden: true,
      editable: false,
      headerStyle: utilTable.styleHeaderIncidences(),
      style: utilTable.styleHeaderIncidences(),
    }, {
      dataField: 'moment',
      text: languages[lang].validation_moment,
      hidden: true,
      editable: false,
      headerStyle: utilTable.styleHeaderIncidences(),
      style: utilTable.styleHeaderIncidences(),
    } ]
if(!JSON.parse(localStorage.siglocanvas)){
  columns.push({
    dataField: 'button',
    text: '',
    formatter: formatWithIconSee,
    //hidden: !this.state.userPermisionTrue,
    editable: false,
    headerStyle: { width: '78px', marginTop: 0, marginBottom: 0, paddingTop: 5, paddingBottom: 5 },
    style: utilTable.styleHeaderIncidences(),
  })
}


  return (

    <GridItem xs={12}
      //style={scrolleable ? { maxHeight: 300, height: 300, overflow: 'auto' } : {}}
      style={scrolleable ? incidencesToExamine.length > 0 ? { maxHeight: 300, height: 300, overflow: 'auto' } : { height: 100, overflow: 'auto' } : {}}
      >
      {
        incidencesToExamine.length > 0 ?
          <GridContainer
          style={{overflow:"scroll",maxHeight:300}}
          id='__tabla__'>
            <GridItem xs={12}>
              <BootstrapTable
                bootstrap4
                keyField="key"
                data={incidencesToExamine}
                columns={columns}
                cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, })}
                striped
               
                bordered={true}
                wrapperClasses="table-responsive"
                classes='scrollable-table'
              />
            </GridItem>
          </GridContainer>
          :
          <GridContainer>
            <GridItem xs={12} style={{ height: 100, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div style={{ fontSize: 19 }}>
                {/* {languages[lang].validation_noIncidentsWereGeneratedInThisExam} */}
                {props.incidenceSelected?
                  <div>
                  {props.incidenceSelected==="Baja"?
                  languages[lang]._low
                  :
                  props.incidenceSelected==="Media"?
                  languages[lang]._medium
                  :
                  props.incidenceSelected==="Alta"?
                  languages[lang]._high
                  :
                  props.incidenceSelected==="Crítica"?
                  languages[lang]._critic
                  :null
                }

                  </div>
                :null}
            </div>
            </GridItem>
          </GridContainer>
      }
    </GridItem >

  )

}

export default KWValidationFormTable

